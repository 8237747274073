/**
 * External dependencies.
 */
import React from "react"
import { Container } from "react-bootstrap"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"
import VideoPlaceholder from "../video-placeholder/video-placeholder"

const IntroContent = ({
  className,
  image,
  video,
  alt,
  imagetransforms,
  id,
  Pagename,
  children,
  fluidContainer = true,
  imgname
}) => (
  <div className={classNames("intro-content", className)}>
    <Container fluid={fluidContainer && 'hg'}>
      <div className="intro-content__inner">{children}</div>
      {image && (
        <div className="intro-content__image">
          <div className="gatsby-image-wrapper">
          <VideoPlaceholder
          // bg={true}
        video={video}
        playBtnOnRight={false}
          image={image}
          alt={image?.alternativeText}
          imagetransforms={imagetransforms}
          id={id}
          disableLazyLoadOnImage={true}
          Pagename={Pagename}
          imgname={imgname}
        />
          {/* <GatsbyImage image={image} alt={alt} /> */}
          </div>
        </div>
      )}
    </Container>
  </div>
)

export default IntroContent
