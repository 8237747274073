/**
 * External dependencies.
 */
 import classNames from "classnames"
 import React, { useState } from "react"
 import Select from "react-select"
 import { Form } from 'react-bootstrap';
 /**
  * Internal dependencies.
  */
 import "./styles/_index.scss"
 
 const IndicatorsContainer = props => {
   return (
     <i className="icon icon--chevron-down me-1_2" style={{ width: "10px" }} />
   )
 }
 
 export const customStyles = (fontSize, borderRadius) => ({
   control: (styles, state) => {
     return {
       ...styles,
       borderColor: state.menuIsOpen ? "transparent" : "#DCDDDE",
       boxShadow: state.menuIsOpen
         ? "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 7px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)"
         : "none",
       height: "56px",
       borderRadius: state.menuIsOpen
         ? `${borderRadius} ${borderRadius} 0 0`
         : borderRadius,
       "&:hover": {
         borderColor: state.menuIsOpen ? "transparent" : "#DCDDDE",
       },
     }
   },
   indicatorSeparator: () => ({
     display: "none",
   }),
   valueContainer: styles => ({
     ...styles,
     paddingLeft: "16px",
     fontSize: "inherit",
   }),
   menu: styles => ({
     ...styles,
     margin: "0",
     padding: 0,
     border: 0,
     borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
     boxShadow:
       "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 7px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)",
   }),
   menuList: styles => ({
     ...styles,
     margin: "0",
     borderBottom: "1px solid #F5F5F5",
   }),
   placeholder: styles => ({
     ...styles,
     fontSize: "inherit",
   }),
   option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
     ...styles,
     color: isSelected ? "#fff" : "#393939",
     backgroundColor: isSelected ? "#026178" : "#fff",
     fontSize: "inherit",
     lineHeight: "20px",
     padding: "8px 20px",
     borderTop: "1px solid #F5F5F5",
     WebkitTapHighlightColor: "#fff",
     ":hover": {
       backgroundColor: "#ffcf14",
       // opacity: 0.7,
     },
   }),
 })
 
 const FieldSelect = ({
   isSearchable,
   field,
   label,
   classNamePrefix,
   onChange,
   options,
   fontSize = "1.4rem",
   borderRadius = "8px",
   styles = {},
   defaultValue = "",
   placeholder,
   name,
   value,
 }) => {
   const [opened, setOpened] = useState(false)
 
   return (
    <Form.Group className='form-wrap'><Form.Label>{label}
                 </Form.Label>
     <Select
     name={name}
     value={value}
       isSearchable={false}
       classNamePrefix={classNamePrefix}
       placeholder={placeholder}
       components={{ IndicatorsContainer }}
       onChange={onChange}
       options={options}
       styles={{ ...customStyles(fontSize, borderRadius), ...styles }}
       defaultValue={defaultValue}
       className={classNames("field-select", { "is-open": opened })}
       onMenuOpen={() => setOpened(true)}
       onMenuClose={() => setOpened(false)}
     />
     </Form.Group>
   )
 }
 
 export default FieldSelect
 

// import React, {useState} from 'react';
// import { Form } from 'react-bootstrap';

// const SelectBox = (props) => {
//     const { options = [], name, handleChange, selected, label } = props;
//     const [open, setopen] = useState(false)
    
//     return (
//         <Form.Group className='form-wrap'>
//             <Form.Label>{label}
//                 {/* <Link href="#" className='info-icons'>
//                     <i className='icon-info'></i>
//                 </Link> */}
//             </Form.Label>
//             <Form.Select 
//             className={open ? "active" : ""} 
//             // onClick={() => setopen(!open)}
//              name={name} onChange={handleChange} value={selected}>
//                 {
//                     options.map((opt, k) => {
//                         return (
//                             <option value={opt.value}>{opt.label}</option>
//                         )
//                     })
//                 }
//             </Form.Select>
//         </Form.Group>
//     )

// }
// export default SelectBox;